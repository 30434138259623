import { Box, Chip, Theme, Tooltip, useTheme } from "@mui/material";
import { formatSnakeCaseToPascalCase, formatTitleCase } from "../../common/utils/formatter";
import {
    MdCheckCircleOutline,
    MdOutlineArrowCircleRight,
    MdOutlineCircle,
    MdOutlinePauseCircleOutline,
    MdOutlineRunCircle,
    MdRemoveCircleOutline,
} from "react-icons/md";
import { BiFastForwardCircle } from "react-icons/bi";
import { RiCloseCircleLine } from "react-icons/ri";
import { TbProgress } from "react-icons/tb";
import { IconBaseProps } from "react-icons";
import { generatePath, useMatch, useNavigate, useParams } from "react-router-dom";
import { getProjectSubRouteTemplate, MIGRATE_OPS_SUBROUTE, PROJECT_SUBROUTE, UNAUTHENTICATED_ROUTES } from "../app/AppRoutes";
import Azure from "../../assets/integration_logos/azure.png";
import AWS from "../../assets/integration_logos/aws.png";
import { ImTree } from "react-icons/im";
import { OperationStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/operation_status_pb";
import { OperationRecipeID } from "gc-web-proto/galaxycompletepb/operationpb/operation_pb";
import ProtoDoc from "../../assets/migrateOps/migrateops-proto.json";
import { IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";

export const useNavigateToOpDetails = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    return (opId: number) => {
        const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS) + `/${opId}`, { projectId });
        navigate(p);
    };
};

export const useNavigateToOpDocs = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS_DOCS), { projectId });
    return () => navigate(p);
};

export const useNavigatetoRecipeReference = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    return (recipeId: OperationRecipeID) => {
        const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS_DOCS) + `/${recipeId}`, { projectId });
        navigate(p);
    };
};

export const useNavgiateToRecipeBuilder = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    return (recipeId: OperationRecipeID) => {
        const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS) + `/${MIGRATE_OPS_SUBROUTE.WIZARD}` + `/${recipeId}`, { projectId });
        navigate(p);
    };
};

export const useNavigateToOpsList = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS), { projectId });
    return () => navigate(p);
};

export const useNavigateToNewOpScreen = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS) + `/${MIGRATE_OPS_SUBROUTE.CREATE}`, {
        projectId,
    });
    return () => navigate(p);
};

export const useNavigateToNewOpBuilder = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS) + `/${MIGRATE_OPS_SUBROUTE.BUILDER}`, {
        projectId,
    });
    return () => navigate(p);
};

// ======================
// MigrateOpStatusChip
// ======================

interface MigrateOpStatusChipProps {
    status: OperationStatus.OperationStatus;
}

export const MigrateOpStatusChip: React.FC<MigrateOpStatusChipProps> = (p) => {
    const { status } = p;

    return (
        <Chip
            icon={getMigrateOpStatusIcon(status)}
            sx={{ backgroundColor: getMigrateOpStatusColor(status) }}
            size={"small"}
            label={getMigrateOpStatusLabel(status)}
        />
    );
};

export const getMigrateOpStatusLabel = (status: OperationStatus.OperationStatus) => {
    const key = Object.keys(OperationStatus.OperationStatus).find(
        (key) => OperationStatus.OperationStatus[key as keyof typeof OperationStatus.OperationStatus] === status
    );

    return key?.replace(/_/g, " ").split(" ").map(formatTitleCase).join(" ");
};

export const getMigrateOpStatusColor = (status: OperationStatus.OperationStatus) => {
    if (status === OperationStatus.OperationStatus.COMPLETED) {
        return (t: Theme) => t.palette.success.main;
    }
    if (status === OperationStatus.OperationStatus.FAILED) {
        return (t: Theme) => t.palette.error.main;
    }
    if (status === OperationStatus.OperationStatus.RUNNING) {
        return (t: Theme) => t.palette.info.main;
    }
    if (status === OperationStatus.OperationStatus.CANCELLED) {
        return (t: Theme) => t.palette.grey["500"];
    }
    if (status === OperationStatus.OperationStatus.PENDING) {
        return (t: Theme) => t.palette.grey["500"];
    }
    if (status === OperationStatus.OperationStatus.SKIPPED) {
        return (t: Theme) => t.palette.grey["500"];
    }
    if (status === OperationStatus.OperationStatus.PAUSED) {
        return (t: Theme) => t.palette.grey["500"];
    }
    if (status === OperationStatus.OperationStatus.AWAITING_USER_INPUT) {
        return (t: Theme) => t.palette.warning.main;
    }
    if (status === OperationStatus.OperationStatus.QUEUED) {
        return (t: Theme) => t.palette.warning.main;
    }
    if (status === OperationStatus.OperationStatus.WAITING) {
        return (t: Theme) => t.palette.warning.main;
    }
    return (t: Theme) => t.palette.grey["500"];
};

// ======================
// MigrateOpsStatusIcon
// ======================

interface MigrateOpsStatusIconProps {
    status: OperationStatus.OperationStatus;
    tooltip?: boolean;
}

export const MigrateOpsStatusIcon: React.FC<MigrateOpsStatusIconProps & Partial<IconBaseProps>> = (p) => {
    const { status, tooltip, ...iconBaseProps } = p;
    const theme = useTheme();

    const icon = (
        <Box>
            {getMigrateOpStatusIcon(status, {
                color: getMigrateOpStatusColor(status)(theme),
                ...iconBaseProps,
            })}
        </Box>
    );

    return tooltip ? (
        <Tooltip arrow title={`Status: ${getMigrateOpStatusLabel(status)}`}>
            {icon}
        </Tooltip>
    ) : (
        icon
    );
};

export const getMigrateOpStatusIcon = (status: OperationStatus.OperationStatus, props?: IconBaseProps) => {
    if (status === OperationStatus.OperationStatus.COMPLETED) {
        return <MdCheckCircleOutline {...props} />;
    }
    if (status === OperationStatus.OperationStatus.FAILED) {
        return <RiCloseCircleLine {...props} />;
    }
    if (status === OperationStatus.OperationStatus.RUNNING) {
        return <MdOutlineRunCircle {...props} />;
    }
    if (status === OperationStatus.OperationStatus.CANCELLED) {
        return <MdRemoveCircleOutline {...props} />;
    }
    if (status === OperationStatus.OperationStatus.PENDING) {
        return <MdOutlineCircle {...props} />;
    }
    if (status === OperationStatus.OperationStatus.SKIPPED) {
        return <BiFastForwardCircle {...props} />;
    }
    if (status === OperationStatus.OperationStatus.PAUSED) {
        return <MdOutlinePauseCircleOutline {...props} />;
    }
    if (status === OperationStatus.OperationStatus.AWAITING_USER_INPUT) {
        return <TbProgress {...props} />;
    }
    if (status === OperationStatus.OperationStatus.QUEUED) {
        return <MdOutlineArrowCircleRight {...props} />;
    }
    if (status === OperationStatus.OperationStatus.WAITING) {
        return <MdOutlineRunCircle {...props} />;
    }
    return <MdCheckCircleOutline {...props} />;
};

export const getOperationRecipeLabel = (opRecipe: OperationRecipeID) => {
    const operationProto = ProtoDoc.files.find((file) => file.name === "galaxycompletepb/operationpb/operation.proto");
    const recipeEnumValues = operationProto.enums.find((e) => e.name === "OperationRecipeID").values;

    return recipeEnumValues.find((v: any) => v.number === opRecipe.toString())?.description || "Other Recipe";
};

export const getOperationRecipeDescription = (opRecipe: OperationRecipeID) => {
    const operationProto = ProtoDoc.files.find((file) => file.name === "galaxycompletepb/operationpb/operation.proto");
    const recipeEnumValues = operationProto.enums.find((e) => e.name === "OperationRecipeID").values;
    const recipeKey = recipeEnumValues.find((v: any) => v.number === opRecipe.toString())?.name.toLowerCase();
    return operationProto.messages.find((m) => m.name === "OperationConfig")?.fields.find((f) => f.name === recipeKey)?.description;
};

export const getOperationRecipeFromIntegrationModule = (module: IntegrationModule) => {
    if (module === IntegrationModule.NUTANIX) {
        return OperationRecipeID.MIGRATEOPS_NUTANIX_COMPUTE;
    }
    if (module === IntegrationModule.AWS) {
        return OperationRecipeID.MIGRATEOPS_AWS_COMPUTE;
    }
    if (module === IntegrationModule.AZURE) {
        return OperationRecipeID.MIGRATEOPS_AZURE_COMPUTE;
    }
    // if (module === IntegrationModule.HYPERV) {
    //     return OperationRecipeID.MIGRATEOPS_HYPERV_COMPUTE;
    // }
};

export const getOperationRecipeIcon = (opRecipe: OperationRecipeID) => {
    if (opRecipe === OperationRecipeID.MIGRATEOPS_AWS_COMPUTE) {
        return <img alt={"AWS"} src={AWS} style={{ width: 60 }} />;
    }
    if (opRecipe === OperationRecipeID.MIGRATEOPS_AZURE_COMPUTE) {
        return <img alt={"Azure"} src={Azure} style={{ width: 60 }} />;
    }
    if (opRecipe === OperationRecipeID.MIGRATEOPS_LOCAL_DATA_MIGRATION) {
        return <ImTree size={60} />;
    }
};

interface OperationRecipeConfig {
    label: string;
    description: string;
    icon: React.ReactNode;
}

export const getOperationRecipeConfig = (opRecipe: OperationRecipeID): OperationRecipeConfig => {
    if (opRecipe === OperationRecipeID.MIGRATEOPS_AZURE_COMPUTE) {
        return {
            label: getOperationRecipeLabel(opRecipe),
            description: "Azure description goes here.",
            icon: getOperationRecipeIcon(opRecipe),
        };
    }
    if (opRecipe === OperationRecipeID.MIGRATEOPS_AWS_COMPUTE) {
        return {
            label: getOperationRecipeLabel(opRecipe),
            description: "AWS description goes here.",
            icon: getOperationRecipeIcon(opRecipe),
        };
    }
    if (opRecipe === OperationRecipeID.MIGRATEOPS_LOCAL_DATA_MIGRATION) {
        return {
            label: getOperationRecipeLabel(opRecipe),
            description: "Local description goes here.",
            icon: getOperationRecipeIcon(opRecipe),
        };
    }
};

export const matchOperationRecipeIdKeyToConfigField = (recipeIdKey: string) => {
    const formattedId = formatSnakeCaseToPascalCase(recipeIdKey.replace("MIGRATEOPS_", ""));
    return `${formattedId}.Config`;
};
