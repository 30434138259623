import { observer } from "mobx-react-lite";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import React from "react";
import { ActionConfig, ActionMenuButton } from "../../common/actions/CommonActions";
import { BackButton } from "../../common/CommonButtons";
import { getModuleConfigByModule, IntegrationCard, useNavigateToEditIntegrationForm, useNavigateToEnabledIntegrations } from "./IntegrationsCommon";
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogContentText,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    SvgIcon,
} from "@mui/material";
import { IntegrationsIcon, MigrationIcon } from "../project/ProjectCommon";
import { DeleteIcon, EditIcon } from "../../common/CommonIcons";
import { useAppServices } from "../app/services";
import { useParams } from "react-router-dom";
import { IntegrationConfigInfo, IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { getUserFullNameFromObject } from "../settings/ProjectUsers";
import { useGmAutoAllocationState } from "../cmcMigration/autoallocation/GmAutoAllocationCommon";
import { DialogState, useDialogState } from "../core/dialog/DialogService";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { Form, Formik } from "formik";
import { VerifyIntegrationConnection } from "./IntegrationForm/IntegrationForm";
import { GalaxyMigrateAutoAllocationProgressDialog } from "../cmcMigration/autoallocation/GmAutoAllocationProgress";
import { BsGearFill } from "react-icons/bs";
import { OperatorView } from "../auth/AuthenticatedViews";
import { v4 as uuid } from "uuid";
import { useGetEnabledIntegrationDetails, useListIntegrationModules, useRemoveProjectIntegration, useTestProjectIntegration } from "./integration_hooks";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { getOperationRecipeFromIntegrationModule } from "../migrateops/MigrateOpsCommon";
import { useNavigateToMigrateOpsRecipeWizard } from "../migrateops/wizard/MigrateOpsWizardCommon";

// ======================
// EnabledIntegrationsDetails
// ======================

interface EnabledIntegrationsDetailsProps {}

export const EnabledIntegrationsDetails: React.FC<EnabledIntegrationsDetailsProps> = observer((p) => {
    const goBackToEnabledIntegrations = useNavigateToEnabledIntegrations();
    const { integrationId } = useParams();
    const goToEditForm = useNavigateToEditIntegrationForm();
    const id = parseInt(integrationId);
    const testIntegrationDialogState = useDialogState();
    const integrationDetails = useGetEnabledIntegrationDetails(Number(integrationId));
    const removeIntegration = useRemoveProjectIntegration();
    const globalDialogState = useGlobalDialogState();
    const navigateToMigrateOpsRecipeWizard = useNavigateToMigrateOpsRecipeWizard();

    const editIntegration = async () => {
        goToEditForm(integrationId);
    };

    const eligibleMigrateOpsRecipeIntegrations = [IntegrationModule.AWS];

    const getActions = (): ActionConfig[] => {
        return [
            {
                id: "edit",
                name: "Edit Integration",
                action: editIntegration,
                icon: <EditIcon />,
            },
            {
                id: "test",
                name: "Test Integration",
                action: () => {
                    testIntegrationDialogState.setDialogProps({ id: id });
                    testIntegrationDialogState.open();
                },
                icon: <IntegrationsIcon size={24} />,
            },
            {
                id: "migrate",
                name: "Migrate via Integration",
                action: () => {
                    navigateToMigrateOpsRecipeWizard(getOperationRecipeFromIntegrationModule(integrationDetails.data?.info?.module));
                },
                icon: <MigrationIcon size={24} />,
                hidden: !eligibleMigrateOpsRecipeIntegrations.includes(integrationDetails.data?.info?.module),
            },
            {
                id: "remove",
                name: "Remove Integration",
                action: async () => {
                    const confirmed = await globalDialogState.addConfirmDialog({
                        message: "Are you sure you want to remove this integration?",
                        autoConfirmationQuestionLine: false,
                    });
                    if (confirmed) {
                        await removeIntegration.mutateAsync(id);
                        goBackToEnabledIntegrations();
                    }
                },
                icon: <DeleteIcon />,
            },
        ];
    };

    return (
        <ScreenContainer>
            <BackButton navFunction={goBackToEnabledIntegrations} label={"Back to Enabled Integrations"} />
            <QueryResultWrapper queryResult={integrationDetails}>
                <ScreenTitleBar
                    title={integrationDetails.data?.info.friendlyName}
                    actions={
                        <OperatorView>
                            <ActionMenuButton
                                actions={getActions()}
                                buttonLabel={"Integration Actions"}
                                buttonProps={{
                                    endIcon: (
                                        <SvgIcon>
                                            <BsGearFill />
                                        </SvgIcon>
                                    ),
                                    color: "secondary",
                                    variant: "contained",
                                }}
                            />
                        </OperatorView>
                    }
                />
                <IntegrationDetails data={integrationDetails.data?.info} />
                <TestIntegrationDialog dialogState={testIntegrationDialogState} />
                <GalaxyMigrateAutoAllocationProgressDialog actionLabel={"Close"} />
            </QueryResultWrapper>
        </ScreenContainer>
    );
});

// ======================
// IntegrationDetails
// ======================

interface IntegrationDetailsProps {
    data: IntegrationConfigInfo.AsObject;
}

export const IntegrationDetails: React.FC<IntegrationDetailsProps> = observer((p) => {
    const integrationDefs = useListIntegrationModules();
    return (
        <Grid container spacing={3} justifyContent={"flex-start"}>
            <Grid item xs={12} sm={6} md={3} xl={2}>
                <QueryResultWrapper queryResult={integrationDefs}>
                    <IntegrationCard
                        module={getModuleConfigByModule(p.data.module, integrationDefs.data?.itemsList)}
                        cardProps={{ sx: { height: "100%", width: "100%" } }}
                    />
                </QueryResultWrapper>
            </Grid>
            <Grid item xs={12} sm={6} md={9} xl={10}>
                <Card sx={{ height: "100%", width: "100%" }}>
                    <CardContent>
                        <Grid container spacing={3} component={Box} p={2}>
                            <ListSubheader>Integration Information</ListSubheader>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={p.data.friendlyName} secondary={`Name`} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={p.data.description || "N/A"} secondary={`Description`} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={`#${p.data.id}`} secondary={`ID`} />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={formatKnownDataType(p.data.enabledAt, KnownDataType.DATE)} secondary={`Enabled At`} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={getUserFullNameFromObject(p.data.enabledBy)} secondary={`Enabled By`} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
});

// ======================
// TestIntegrationDialog
// ======================

interface TestIntegrationDialogProps {
    dialogState: DialogState;
}

export const TestIntegrationDialog: React.FC<TestIntegrationDialogProps> = observer((p) => {
    const { deploymentService } = useAppServices();
    const state = useGmAutoAllocationState();
    const id = p.dialogState.dialogProps?.id;
    const testProjectIntegration = useTestProjectIntegration();
    const initialValues = {
        deploymentId: "",
    };

    if (id >= 0) {
        return (
            <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close}>
                <DialogTopBar dialogState={p.dialogState} title={"Test Integration Connection"} />
                <DialogContent>
                    <DialogContentText>Choose a host to test the integration:</DialogContentText>
                    <br />
                    <Formik
                        initialValues={initialValues}
                        onSubmit={async (values) => {
                            const deploymentDetails = await deploymentService.fetchDeploymentDetails(values.deploymentId);
                            state.setDeployment(deploymentDetails);
                            const statusId = uuid();
                            state.setNewStatusId(statusId);
                            state.setShowProgressDialog(true);
                            await testProjectIntegration.mutateAsync({
                                deploymentId: values.deploymentId,
                                id,
                                statusId,
                            });
                            await state.waitForProgressDialogToClose();
                            p.dialogState.close();
                        }}
                    >
                        {(props) => {
                            return (
                                <Form>
                                    <Box pb={2}>
                                        <VerifyIntegrationConnection />
                                        <Box display={"flex"}></Box>

                                        <Box display={"flex"} justifyContent={"center"} pt={2}>
                                            <Button variant={"contained"} color={"primary"} type={"submit"}>
                                                Test Connection
                                            </Button>
                                        </Box>
                                    </Box>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }
    return null;
});
